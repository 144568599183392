<template>
  <p v-if="showCrawlingStartedMessage">
    Danke für die Bestätigung Deiner Email! Wir analysieren Dein Blog und
    schicken dir die Ergebnisse per Email, sobald sie fertig sind.
  </p>
  <p v-else-if="showNeedEmailConfirmationMessage">
    Wir brauchen eine Email Bestätigung von dir, damit wir dir die
    Evaluierungsergebnisse mitteilen können. Wir haben dir eine Email mit dem
    Code geschickt, damit Du Deine Email bestätigen kannst.
  </p>
  <p v-else-if="response" class="are-you-worthy__result">
    Wir analysieren Dein Blog und schicken dir die Ergebnisse per Email, sobald
    sie fertig sind.
  </p>
  <form
    v-else
    @submit.prevent="check"
    class="are-you-worthy et_pb_contact_form"
  >
    <p>Finde heraus, ob Du mit Deiner Webseite Geld verdienen kannst.</p>
    <p class="et_pb_contact_field et_pb_contact_field_half">
      <label for="are-you-worthy-url" class="et_pb_contact_form_label">
        Dein Blog:
      </label>
      <input
        id="are-you-worthy-url"
        name="url"
        v-model="url"
        placeholder="URL Deines Blogs"
        type="text"
        class="input"
        :class="{
          et_contact_error: showValidationErrors && !this.isUrlValid,
        }"
      />
    </p>

    <p
      class="et_pb_contact_field et_pb_contact_field_half et_pb_contact_field_last"
    >
      <label for="are-you-worthy-email" class="et_pb_contact_form_label">
        Deine E-Mail-Adresse:
      </label>
      <input
        id="are-you-worthy-email"
        name="email"
        v-model="email"
        placeholder="E-Mail-Adresse"
        type="text"
        class="input"
        :class="{
          et_contact_error: showValidationErrors && !this.isEmailValid,
        }"
      />
    </p>
    <p
      class="et_pb_contact_field et_pb_contact_field_last et_pb_text_align_left"
      data-type="checkbox"
      :class="{
        et_contact_error: showValidationErrors && !this.isConsentValid,
      }"
    >
      <span class="et_pb_contact_field_options_wrapper">
        <span class="et_pb_contact_field_options_list">
          <span class="et_pb_contact_field_checkbox">
            <input
              id="are-you-worthy-consent"
              type="checkbox"
              v-model="consent"
              class="input"
            />
            <label for="are-you-worthy-consent">
              <i></i>
              Ich habe die <a :href="this.datenschutzerklaerung" target="_blank">Datenschutzerklärung</a> zur Kenntnis genommen und
              stimme der Erhebung, Speicherung und Verarbeitung der zuvor
              angegebenen Daten zu.
            </label>
          </span>
        </span>
      </span>
    </p>

    <div v-if="error" class="are-you-worthy__error-message">
      Oops! Etwas ist schief gelaufen. Versuche es später noch einmal oder
      kontaktiere uns.
      <!-- TODO put our contact email here -->
    </div>

    <div
      class="et_pb_button_module_wrapper et_pb_button_0_wrapper et_pb_button_alignment_center et_pb_module"
    >
      <button
        :disabled="isSubmitButtonDisabled"
        class="et_pb_button et_pb_button_0 et_hover_enabled et_pb_bg_layout_light"
      >
        Prüfen
      </button>
    </div>
  </form>
</template>

<script>
import axios from "axios";

export default {
  name: "AreYouWorthy",
  data: function () {
    return {
      url: "",
      email: undefined,
      consent: false,
      response: undefined,
      error: undefined,
      showValidationErrors: false,
      isRequestPending: false,
    };
  },
  props: {
    api: String,
    datenschutzerklaerung: String,
  },
  methods: {
    check: async function () {
      if (!this.isFormValid) {
        this.showValidationErrors = true;
        return;
      }
      const apiUrl = this.api || "/api/check";
      this.isRequestPending = true;
      let response;
      try {
        response = await axios.post(apiUrl, {
          url: this.url,
          email: this.email,
          consent: this.consent.toString(),
        });
      } catch (e) {
        this.error = true;
        return;
      } finally {
        this.isRequestPending = false;
      }
      this.error = false;
      this.response = response.data;
    },
  },
  computed: {
    isFormValid() {
      return this.isUrlValid && this.isEmailValid && this.isConsentValid;
    },
    isUrlValid() {
      return this.url;
    },
    isEmailValid() {
      return this.email;
    },
    isConsentValid() {
      return this.consent;
    },
    isSubmitButtonDisabled() {
      return (
        (this.showValidationErrors && !this.isFormValid) ||
        this.isRequestPending
      );
    },
    showCrawlingStartedMessage() {
      return location.toString().includes("crawlingStarted=true");
    },
    showNeedEmailConfirmationMessage() {
      return this.response?.result === "sentConfirmationEmail";
    },
  },
};
</script>

<style scoped lang="scss">
.are-you-worthy {
  .et_pb_button:hover {
    cursor: pointer;
  }
  &__result {
    margin: 1em 0;
  }
  &__error-message {
    margin: 1em 0;
  }
}
</style>
