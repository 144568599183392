import Vue from 'vue'
import App from './App.vue'

Vue.config.productionTip = false

const MOUNT_ELEMENT_ID = "are-you-worthy";

const mountApp = () => {
  const mountElement = document.getElementById(MOUNT_ELEMENT_ID);
  const mountElementDataProps = { ...mountElement.dataset };
  new Vue({
    render: (h) => h(App, { props: mountElementDataProps }),
  }).$mount(mountElement);
};

document.addEventListener("DOMContentLoaded", () => {
  mountApp();
});
