<template>
  <div
    id="are-you-worthy"
    class="et_pb_module et_pb_text et_pb_text_1 et_pb_text_align_center et_pb_bg_layout_light et_pb_contact"
  >
    <div class="et_pb_text_inner">
      <h2>Are you worthy?</h2>
    </div>
    <AreYouWorthy :api="this.api" :datenschutzerklaerung="this.datenschutzerklaerung"/>
  </div>
</template>

<script>
import AreYouWorthy from "@/components/AreYouWorthy";

export default {
  name: "App",
  components: {
    AreYouWorthy,
  },
  props: {
    api: String,
    datenschutzerklaerung: String,
  },
};
</script>

<style>
</style>
